import React from 'react';
import { Layout, SEO, Container } from 'components/common';
import { Intro, Skills, Contact } from 'components/landing';

export default () => (
  <Layout>
    <SEO />
    <Intro />
    <Skills />
    <Container>
      <h2>Get in touch with me</h2>
      <p>
        You can send me message on{' '}
        <b>
          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/vdespa/">
            LinkedIn{' '}
          </a>
          or{' '}
          <a target="_blank" rel="noreferrer" href="https://www.twitter.com/vdespa/">
            Twitter
          </a>
        </b>
        . I cannot guarantee a reply but I will do my best.
      </p>
    </Container>
  </Layout>
);
